import React from "react"
import "uswds/dist/css/uswds.min.css"
import "uswds/dist/js/uswds.js"
import Footer from "../components/footer"
import ScrollToTop from "../components/scrollToTop"
import ContentId from '../images/content-id'
import ContentTypes from "../images/content-types"
import HomeLabeled from "../images/home_labeled"
import Link from "../images/link"
import Wysiwyg from "../images/wysiwyg"

export default function Guide() {
  return (
    <div>
      <main id="main-content" className="flex-1">
        <section className="grid-container">
          <div className="text-center margin-y-9">
            <h1 className="font-serif-2xl">ICOR Content Data Entry User Guide</h1>
          </div>
        </section>
        <section className="grid-container display-flex flex-column flex-align-center flex-justify-center">
          <div>
            <h2 className="font-serif-lg margin-bottom-0">User Access</h2>
            <p className="font-sans-md measure-6">
              Data entry is done at <a href="https://icor-cms-uat.noe.eoir.usdoj.gov" target="_blank" rel="noopener noreferrer">https://icor-cms-uat.noe.eoir.usdoj.gov</a>.
              Please contact the ICOR Support team if you do not have access.
              Your role as a Contributor means you can create, edit, delete, and publish content.
            </p>
          </div>
        </section>
        <section className="grid-container display-flex flex-column flex-align-center flex-justify-center margin-bottom-9">
          <div>
            <h2 className="font-serif-lg margin-bottom-0">Basics</h2>
            <p className="font-sans-md measure-6">
              A <strong>content management system (CMS)</strong> is an application that is used to manage content,
              allowing multiple contributors to create, edit and publish. Content in a CMS is typically stored in a database
              and displayed in a presentation layer based on a set of templates like a website. ICOR uses Orchard CMS to manage
              content across the site while the presentation layer is handled by the ICOR Support team. As a general guideline,
              look to the CMS for changing content, and consult the ICOR Support team to change functionality such as creating new pages,
              or modifying the behavior of a button.
            </p>
            <p className="font-sans-md measure-6">
              Content is organized in a hierarchical structure where <strong>content types</strong> are at the base of the structure. Using
              content types, we take inventory of the various kinds of content that should be on the site based off of requirements and
              create each content type with an appropriate name. Each content type is then broken up into items known as a <strong>post</strong> and
              can be accessed by clicking on "List Items" beside each content type.
            </p>
          </div>
          <figure className="usa-card__container padding-2 display-flex flex-justify-center flex-align-center">
            <ContentTypes />
            <figcaption>Content Types within Orchard CMS</figcaption>
          </figure>
          <figure className="usa-card__container padding-2 display-flex flex-justify-center flex-align-center">
            <HomeLabeled />
            <figcaption>
              Content Types being displayed in the site. Notice that each <strong>Card</strong> post results in a card on the page.
            </figcaption>
          </figure>
        </section>
        <section className="grid-container display-flex flex-column flex-align-center flex-justify-center margin-bottom-9">
          <div>
            <h2 className="font-serif-lg margin-bottom-0">Content Entry</h2>
            <p className="font-sans-md measure-6">
              While entering content you will eventually come across the WYSIWYG (What You See Is What You Get) editor which helps you
              format the content you enter much like how Microsoft does. Unlike Microsoft Word, the WYSIWYG
              editor formats text for a web friendly format known as HTML (Hyper Text Markup Language). To avoid
              formatting issues, when copying and pasting content from other sources such as Word, Excel, or emails, enable the HTML view before pasting.
              You can tell HTML view is enabled if the button is white and the text in the WYSIWYG editor has changed fonts as shown below. From there, you
              can disable the HTML View and use the editor's toolbar to format content much as you would in Word.
            </p>
          </div>

          <figure className="usa-card__container padding-2 display-flex flex-justify-center flex-align-center">
            <Wysiwyg />
            <figcaption>
              Enabling HTML View will reveal the HTML that is formatting your content.
            </figcaption>
          </figure>

          <div>
            <h2 className="font-serif-lg margin-bottom-0">WYSIWYG Tips</h2>
            <p className="font-sans-md measure-6">
              Linking text to another website or resource can be done using the link button in the WYSIWYG editor, but a common
              desire is to have the link open in a new tab or window. To do so, in the target field, add "_blank" as shown in the example.
              Keep in mind opening in a new tab versus a new window is controlled by the user and their browser settings. Remember not to 
              link UAT or QC sites in the CMS. They will not function when the site is deployed to production.
            </p>
          </div>

          <figure className="usa-card__container padding-2 display-flex flex-justify-center flex-align-center">
            <Link />
            <figcaption>
              In the HTML View, this will create <pre>&lt;a href=&quot;www.example.com&quot; target=&quot;_blank&quot;&gt;Example&lt;/a&gt;</pre>
            </figcaption>
          </figure>

          <p className="font-sans-md measure-6">
              When you wish to link a document like a pdf or Word file, use a relative link like so:
              <pre>/practice_tips_and_pointers_dhs_january_2022.pdf</pre>
              The "/" will indicate to the HTML that the link is a path to a resource on the current website rather than a new website to visit.
              To get the file uploaded, please send the file to the ICOR Support team with file name that has no uppercase characters, spaces,
              or symbols other than "-" or "_".
          </p>

          <p className="font-sans-md measure-6">
              When entering content for FAQs, Resources, or Model Hearing Program, you may want to create a link that scroll the user to
              another post in the same page. To do that, you first must get the <strong>content id</strong> of the post by going to the post
              and editing it to view the URL that shows up in the address bar of your browser. Then copy the id as shown in the example
              and use that in your link.
          </p>
          <figure className="usa-card__container padding-2 display-flex flex-justify-center flex-align-center">
            <ContentId />
            <figcaption>
              Make sure you're referring to a post of the same content type.
            </figcaption>
          </figure>
          <p className="font-sans-md measure-6">
              After you copied the id text, go to the post where you wish to make the link and paste it into the link so that the ending HTML will look like this:
          </p>
          <pre>&lt;a href=&quot;/Contents/ContentItems/4b6ehgjn91hr3xqb1tqkd12144&quot;&gt;What is an Accredited Representative?&lt;/a&gt;</pre>
          
          <p className="font-sans-md measure-6">
              For tables, using <a href="https://divtable.com/generator/" target="_blank" rel="noopener noreferrer">this table generator</a> will help you get started. After copying the generated HTML, 
              you will need to add a header to label your columms. Use <pre>&lt;thead&gt;&lt;/thead&gt;</pre> to get the header started and add rows using: <pre>&lt;tr&gt;&lt;/tr&gt;</pre>
              Remember to always label the column header and make sure the number of cells in a row match the number of column headers. Here is the code for the table example below:
          </p>
          <pre>
          &lt;table&gt; <br/>
          &nbsp; &lt;thead&gt; <br/>
            &nbsp; &nbsp; &lt;tr&gt; <br/>
              &nbsp; &nbsp; &nbsp; &lt;th&gt;Song&lt;/th&gt; <br/>
              &nbsp; &nbsp; &nbsp; &lt;th&gt;Artist&lt;/th&gt; <br/>
              &nbsp; &nbsp; &nbsp; &lt;th&gt;Year&lt;/th&gt; <br/>
            &nbsp; &nbsp; &lt;/tr&gt; <br/>
          &nbsp; &lt;/thead&gt; <br/>
          &nbsp; &lt;tbody&gt; <br/>
            &nbsp; &nbsp; &lt;tr&gt; <br/>
              &nbsp; &nbsp; &nbsp; &lt;td&gt;The Sliding Mr. Bones (Next Stop, Pottersville)&lt;/td&gt; <br/>
              &nbsp; &nbsp; &nbsp; &lt;td&gt;Malcolm Lockyer&lt;/td&gt; <br/>
              &nbsp; &nbsp; &nbsp; &lt;td&gt;1961&lt;/td&gt; <br/>
            &nbsp; &nbsp; &lt;/tr&gt; <br/>
            &nbsp; &nbsp; &lt;tr&gt; <br/>
              &nbsp; &nbsp; &nbsp; &lt;td&gt;Witchy Woman&lt;/td&gt; <br/>
              &nbsp; &nbsp; &nbsp; &lt;td&gt;The Eagles&lt;/td&gt; <br/>
              &nbsp; &nbsp; &nbsp; &lt;td&gt;1972&lt;/td&gt; <br/>
            &nbsp; &nbsp; &lt;/tr&gt; <br/>
            &nbsp; &nbsp; &lt;tr&gt; <br/>
              &nbsp; &nbsp; &nbsp; &lt;td&gt;Shining Star&lt;/td&gt; <br/>
              &nbsp; &nbsp; &nbsp; &lt;td&gt;Earth, Wind, and Fire&lt;/td&gt; <br/>
              &nbsp; &nbsp; &nbsp; &lt;td&gt;1975&lt;/td&gt; <br/>
            &nbsp; &nbsp; &lt;/tr&gt; <br/>
          &nbsp; &lt;/tbody&gt; <br/>
        &lt;/table&gt;
          </pre>
          <table>
          <thead>
            <tr>
              <th>Song</th>
              <th>Artist</th>
              <th>Year</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
              <td>Malcolm Lockyer</td>
              <td>1961</td>
            </tr>
            <tr>
              <td>Witchy Woman</td>
              <td>The Eagles</td>
              <td>1972</td>
            </tr>
            <tr>
              <td>Shining Star</td>
              <td>Earth, Wind, and Fire</td>
              <td>1975</td>
            </tr>
          </tbody>
        </table>
          <p className="font-sans-md measure-6">
            For any other questions, please contact the ICOR Support team.
          </p>
        </section>
      </main>
      <Footer/>
      <ScrollToTop />
    </div>
  )
}